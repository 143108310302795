import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Footer from 'components/common/Footer';
import CustomFormSlider from 'uikit/CustomFormSlider/CustomFormStepper';

const logoPath = process.env.REACT_APP_LOGO_PATH;

const AuthorizationLayout = ({ isRegistration, registrationStore }) => {
  return (
    <div className='page authorization'>
      <main className='content'>
        <a href={process.env.REACT_APP_LINK_HOME_PAGE} target='_blank' rel='noreferrer' className='icon-logo-wrapper'>
          <img src={logoPath} alt='Logo' className='icon-logo' />
        </a>
        {isRegistration && (
          <CustomFormSlider steps={[{}, {}, {}, {}]} currentStep={registrationStore.currentRegistrationStep} />
        )}
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

AuthorizationLayout.propTypes = {
  registrationStore: MobXPropTypes.observableObject,
  isRegistration: PropTypes.any
};

export default inject('registrationStore')(observer(AuthorizationLayout));
