import request from 'superagent';
import noCache from 'superagent-no-cache';

import {
  getRefreshToken,
  getAccessToken,
  saveTokenInfo,
  logoutActiveSessionWithoutToken,
  setConfirmationActionType,
  setSecureUserPhone,
  setLastUserActivityDateTime,
  resetLastUserActivityDateTime
} from './authUtils';
import { errorMapper } from './Error';
import { BACKEND_MODULE_TYPES } from '../components/common/constants';

const requestAgent = request.agent().use(noCache);

const {
  REACT_APP_API_URL_PATH_PREFIX,
  REACT_APP_API_HOST_WITH_PORT,
  REACT_APP_OAUTH_PATH,
  REACT_APP_OAUTH_URL_PATH_PREFIX,
  REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
  REACT_APP_MESSAGER_API_URL_PATH_PREFIX
} = process.env;

const TRANSPORT_DELAY = 20; // max seconds client waits for server response.
let isRefreshingToken = false;

/*
 "refreshTokenAwaiter" is a method that is used to create an artificial delay for any server calls if a request
 for refreshing the access token has been sent and a response with a new access token is expected.
 The delay step in this method is 100ms.
 The maximum number of iterations is 200, which is equal to the value of TRANSPORT_DELAY = 20sec.
 This method is handled using the "isRefreshingToken" variable.
 */
const refreshTokenAwaiter = () => {
  return new Promise((resolve) => {
    let count = 0;
    const interval = setInterval(() => {
      if (!isRefreshingToken) {
        clearInterval(interval);
        resolve();

        return;
      }
      count++;
      if (count > 200) {
        // 200 * 100ms (timeout) = 20 sec (TRANSPORT_DELAY)
        clearInterval(interval);
        resolve(errorMapper('invalid_refresh_token'));
      }
    }, 100);
  });
};

// Common method! We need to use it for all API-calls
export const api = async (args) => {
  const {
    path: urlPathPostfix,
    method = 'post',
    query = {},
    data = {},
    pathHost = REACT_APP_API_HOST_WITH_PORT,
    pathPrefix = REACT_APP_API_URL_PATH_PREFIX,
    isRequestWithAuth = true,
    isRequestWithBasicAuth = false,
    securityCode,
    returnFullResponse = false,
    responseType = null,
    captcha = null
  } = args;

  if (isRefreshingToken) {
    await refreshTokenAwaiter();
  }

  // eslint-disable-next-line no-console
  console.debug(
    '### API',
    method.toUpperCase(),
    'REQUEST',
    urlPathPostfix,
    ...((method === 'post' && ['\n', data]) ||
      (method === 'get' && query && Object.keys(query).length && ['\n', query]) ||
      [])
  );

  const req = requestAgent[method](pathHost + pathPrefix + urlPathPostfix);

  if (isRequestWithAuth) {
    req.auth(getAccessToken(), { type: 'bearer' });
  }

  if (isRequestWithBasicAuth) {
    req.auth('web', '');
  }

  if (responseType) {
    req.responseType(responseType);
  }

  if (query && Object.keys(query).length !== 0) {
    req.query(query);
  }

  if (method === 'post') {
    req.send(data);
  }

  if (securityCode) {
    req.set('Confirmation-Code', securityCode);
  }

  if (captcha) {
    req.set('Captcha', captcha);
  }

  setLastUserActivityDateTime();

  try {
    const res = await req; // XXX: `body` is `null` for 204 response.
    // eslint-disable-next-line no-console
    console.log('RES api:', res);
    // eslint-disable-next-line no-console
    console.debug('### API', method.toUpperCase(), 'RESPONSE', res?.body);
    return returnFullResponse ? res : res.body;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug('### API', method.toUpperCase(), 'ERROR', error);
    return errorMapper(error);
  }
};

/* █████████████████████ *\
 * ███ OAUTH-related ███ *
\* █████████████████████ */

let tokenTimeoutId;

const tokenize = (data) => async () => {
  if (isRefreshingToken) return;

  try {
    isRefreshingToken = true;

    const {
      body: {
        access_token: accessToken,
        refresh_token: refreshToken,
        token_type: tokenType,
        expires_in: expiresIn, // access_token life-span in seconds.
        phone,
        two_factor_auth_type: twoFactorAuthType,
        confirmation_id: confirmationId
      }
    } = await requestAgent
      .post(REACT_APP_OAUTH_PATH + '/token')
      .type('form')
      .auth('web', '')
      .send(data);

    saveTokenInfo({ accessToken, refreshToken, tokenType, accessExpiresOn: Date.now() + expiresIn * 1000 });
    setTokenRefresh(expiresIn);
    if (twoFactorAuthType) {
      setConfirmationActionType(twoFactorAuthType);
    }
    if (phone) {
      setSecureUserPhone(phone);
    }

    return { twoFactorAuthType, phone, confirmationId };
  } catch (error) {
    return errorMapper(error);
  } finally {
    isRefreshingToken = false;
  }
};

/**
 * @param {number} expiresIn - number of seconds after which access_token expires.
 */
export const setTokenRefresh = (expiresIn) => {
  clearTimeout(tokenTimeoutId);
  tokenTimeoutId = setTimeout(
    tokenize({
      grant_type: 'refresh_token',
      refresh_token: getRefreshToken()
    }),
    (expiresIn - TRANSPORT_DELAY) * 1000
  );
};

export const loginUser = ({ phone, password }) =>
  tokenize({
    phone,
    password,
    grant_type: 'password'
  })();

export const loginPin = (code, confirmationId) =>
  tokenize({
    code,
    grant_type: 'confirmation_code',
    access_token: getAccessToken(),
    confirmation_id: confirmationId
  })();

export const invalidateToken = () =>
  api({
    path: '/revoke',
    pathHost: REACT_APP_OAUTH_PATH,
    pathPrefix: '',
    isRequestWithAuth: false,
    isRequestWithBasicAuth: true,
    query: { token: getAccessToken() }
  });

export const runLogout = async () => {
  await invalidateToken();
  resetLastUserActivityDateTime();
  clearTimeout(tokenTimeoutId);
  logoutActiveSessionWithoutToken();
};

export const enableGoogle2FARequest = async () => {
  const res = await api({
    path: '/user/2fa/google-auth/activate',
    method: 'get',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX
  });

  return { ...res };
};

export const disableGoogle2FARequest = async () => {
  await api({
    path: '/user/2fa/sms/activate',
    method: 'get',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX
  });
};

export const confirmGoogle2FARequest = async (data) => {
  await api({
    path: '/user/2fa/google-auth/activate/confirm',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    data
  });
};

export const getGoogle2FAForce = async () => {
  const res = await api({
    path: '/confirmation/force/2fa',
    method: 'get',
    pathPrefix: REACT_APP_OAUTH_URL_PATH_PREFIX
  });

  return { ...res };
};

export const getSmsCodeForce = async () => {
  const res = await api({
    path: '/confirmation/force/sms',
    method: 'get',
    pathPrefix: REACT_APP_OAUTH_URL_PATH_PREFIX
  });

  return { ...res };
};

/* ████████████████████████████ *\
 * ███ REGISTRATION-related ███ *
\* ████████████████████████████ */

let registrationResponseId = null;

const register = async (urlPathPostfix, data, captcha = null) => {
  try {
    const {
      body: { response_id, user_id, token, user }
    } = await requestAgent
      .post(REACT_APP_API_HOST_WITH_PORT + REACT_APP_API_URL_PATH_PREFIX + urlPathPostfix)
      .set('Captcha', captcha)
      .send(data);

    registrationResponseId = response_id;

    if (user_id) {
      return { response_id, user_id };
    } else if (token && user) {
      saveTokenInfo({
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
        tokenType: token.token_type,
        accessExpiresOn: Date.now() + token.expires_in * 1000
      });
      setTokenRefresh(token.expires_in);
      setConfirmationActionType(token.two_factor_auth_type);
      setSecureUserPhone(user.phone);

      return { token, user };
    } else {
      return { response_id };
    }
  } catch (error) {
    return errorMapper(error);
  }
};

export const createUser = (data, captcha) => register('/registration/user', data, captcha);

export const confirmSmsCode = (code, utmParams) =>
  register('/registration/user/phone/confirm', {
    code,
    response_id: registrationResponseId,
    utm_tags: utmParams
  });

export const resendPhoneRegistration = () =>
  register('/registration/user/phone/resend_code', {
    response_id: registrationResponseId
  });

export const createAccount = async (data) => {
  return api({
    path: '/account/create',
    data
  });
};

export const confirmAccountEmail = (code, id) => {
  return api({
    path: '/account/create/email_confirmation/code',
    data: {
      response_id: id,
      code: code
    }
  });
};

export const resendCodeAccountEmail = (id) => {
  return api({
    path: '/api/account/create/email_confirmation/resend_code',
    data: { response_id: id }
  });
};

/* ████████████████ *\
 * ███ USER-API ███ *
\* ████████████████ */

export const getUserInformation = async () => {
  return api({
    path: '/user',
    method: 'get'
  });
};

export const getUserInfoByCustomerNumber = async (customerNumber) => {
  return api({
    path: `/account/${customerNumber}`,
    method: 'get'
  });
};

/* ████████████████████ *\
 * ███ ACCOUNTS-API ███ *
\* ████████████████████ */

export const getAccounts = async (customerNumber) => {
  return await api({
    path: `/account/${customerNumber}/wallet`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

/* █████████████████████████ *\
 * ███ VERIFICATION CODE ███ *
\* █████████████████████████ */

export const generateSecurityCode = async (module) => {
  const res = await api({
    path: '/confirmation/generate',
    method: 'get',
    pathPrefix:
      module === BACKEND_MODULE_TYPES.API
        ? REACT_APP_PAYMENT_API_URL_PATH_PREFIX
        : module === BACKEND_MODULE_TYPES.PAYMENT
        ? REACT_APP_PAYMENT_API_URL_PATH_PREFIX
        : module === BACKEND_MODULE_TYPES.MESSENGER
        ? REACT_APP_MESSAGER_API_URL_PATH_PREFIX
        : REACT_APP_OAUTH_URL_PATH_PREFIX
  });

  return { ...res };
};

export const resendSecurityCode = (module, confirmationId) =>
  api({
    path: `/confirmation/${confirmationId}/resend`,
    method: 'get',
    pathPrefix:
      module === BACKEND_MODULE_TYPES.API
        ? REACT_APP_PAYMENT_API_URL_PATH_PREFIX
        : module === BACKEND_MODULE_TYPES.PAYMENT
        ? REACT_APP_PAYMENT_API_URL_PATH_PREFIX
        : module === BACKEND_MODULE_TYPES.MESSENGER
        ? REACT_APP_MESSAGER_API_URL_PATH_PREFIX
        : REACT_APP_OAUTH_URL_PATH_PREFIX
  });

/* ███████████████████████████*\
 * ███ RESET USER PASSWORD ███ *
\* ███████████████████████████ */

export const passwordReset = (data) =>
  api({
    path: '/user/change_password',
    data
  });

/* █████████████████████████ *\
 * ███ PASSWORD RECOVERY ███ *
\* █████████████████████████ */

export const passwordRecoveryPhone = (phone) => {
  return api({
    path: '/password_recovery/request',
    isRequestWithAuth: false,
    data: { phone: phone }
  });
};

export const passwordRecoveryPhoneConfirm = (id, code) => {
  return api({
    path: '/password_recovery/phone/confirm',
    isRequestWithAuth: false,
    data: { response_id: id, code: code }
  });
};

export const passwordRecoveryPhoneResend = (id) => {
  return api({
    path: '/password_recovery/phone/resend',
    isRequestWithAuth: false,
    data: { response_id: id }
  });
};

export const passwordRecoveryEmailCheck = (id, email) => {
  return api({
    path: '/password_recovery/email',
    isRequestWithAuth: false,
    data: { response_id: id, email: email }
  });
};

export const passwordRecoveryEmailConfirm = (id, code) => {
  return api({
    path: '/password_recovery/email/confirm',
    isRequestWithAuth: false,
    data: { response_id: id, code: code }
  });
};

export const passwordRecoveryEmailResend = (id) => {
  return api({
    path: '/password_recovery/email/resend',
    isRequestWithAuth: false,
    data: { response_id: id }
  });
};

export const changePasswordUser = (data) =>
  api({
    path: '/password_recovery/set_password',
    isRequestWithAuth: false,
    data
  });

export const passwordRecoveryRequest = (data) =>
  api({
    path: '/password_recovery',
    isRequestWithAuth: false,
    data
  });

export const generatePasswordRecoverySecurityCode = (responseId) =>
  api({
    path: '/password_recovery/request_confirmation',
    isRequestWithAuth: false,
    data: { response_id: responseId }
  });

export const fetchRecoveryLink = (tokenId) =>
  api({
    path: `/password_recovery/email/confirm/${tokenId}`,
    method: 'get',
    isRequestWithAuth: false
  });

/* ████████████████████████ *\
 * ███ TRANSACTIONS-API ███ *
\* ████████████████████████ */

export const getTransactionList = (customerNumber, filter = {}) =>
  api({
    path: `/account/${customerNumber}/transaction`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    query: filter
  });

export const getDocumentsVerificationTransactionList = (customerNumber) =>
  api({
    path: `/account/${customerNumber}/transaction/documents_processing`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

const downloadFile = (response, isPreview = false, filename) => {
  if (!filename && response.headers['content-disposition']) {
    const parts = response.headers['content-disposition'].split('filename=');
    if (parts.length === 2) {
      // eslint-disable-next-line no-param-reassign
      filename = parts[1].replace(/"/g, '');
    }
  }

  const url = URL.createObjectURL(response.body);

  if (isPreview) {
    return url;
  }

  const downloadLink = document.createElement('a');
  downloadLink.style.display = 'none';
  downloadLink.setAttribute('download', filename || 'downloadedFile');
  downloadLink.href = url;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const exportCurrentPDF = async (customerNumber, transactionNumber) => {
  const response = await api({
    path: `/account/${customerNumber}/transaction/${transactionNumber}/report`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get',
    responseType: 'blob',
    returnFullResponse: true
  });

  downloadFile(response);
};

export const exportPDFList = async (customerNumber, query) => {
  const response = await api({
    path: `/account/${customerNumber}/wallet/review`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get',
    query,
    responseType: 'blob',
    returnFullResponse: true
  });

  downloadFile(response);
};

export const exportTransactionListCSV = async (customerNumber, query) => {
  const response = await api({
    path: `/account/${customerNumber}/transaction/export/csv`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    method: 'get',
    query,
    responseType: 'blob',
    returnFullResponse: true
  });

  downloadFile(response);
};

export const uploadDocumentsToTransaction = async (customerNumber, transactionNumber, file) => {
  return api({
    path: `/account/${customerNumber}/document/${transactionNumber}`,
    data: file,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

/* ███████████████████████████████ *\
 * ███ PAYMENT-OBLIGATIONS-API ███ *
\* ███████████████████████████████ */

export const getPaymentObligationsList = (customerNumber, filters = {}) =>
  api({
    path: `/account/${customerNumber}/payment-obligation/list`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    query: filters
  });

/* ██████████████████████████████████ *\
 * ███ APPLICATION-STATE_FROM-API ███ *
\* ██████████████████████████████████*/

export const getApplicationState = () =>
  api({
    path: '/application/state',
    method: 'get'
  });

/* ███████████████████ *\
 * ███ PAYMENT-API ███ *
\* ███████████████████ */

export const resendConfirmationCode = async (uid) =>
  await api({
    path: `/confirmation/${uid}/resend`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const transactionPaymentCheck = async (customerNumber, data) => {
  const res = await api({
    path: `/account/${customerNumber}/transaction/check`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

  return {
    ...res,
    comment: data.comment
  };
};

export const transactionPaymentCreate = async (customerNumber, data) => {
  const res = await api({
    path: `/account/${customerNumber}/transaction/create`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

  return { ...res };
};

export const transactionPaymentConfirm = async (customerNumber, data) => {
  const res = await api({
    path: `/account/${customerNumber}/transaction/confirm`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

  return {
    ...res,
    comment: data.comment
  };
};

export const customerInternalTransactionCheck = async (customerNumber, data) =>
  await api({
    path: `/account/${customerNumber}/transaction/account-internal/check`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

export const customerInternalTransactionCreate = async (customerNumber, data) =>
  await api({
    path: `/account/${customerNumber}/transaction/account-internal/create`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

export const customerInternalTransactionConfirm = async (customerNumber, data) =>
  await api({
    path: `/account/${customerNumber}/transaction/account-internal/confirm`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

export const uploadDocuments = (customerNumber, file) => {
  return api({
    path: `/account/${customerNumber}/document`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data: file
  });
};

export const internalIbanValidate = (accountNumber, iban) => {
  return api({
    path: '/iban/validate',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data: {
      sender_wallet_number: accountNumber,
      recipient_iban: iban
    }
  });
};

/* ████████████████████████ *\
 * ███ VERIFICATION-API ███ *
\* ████████████████████████ */

export const beginVerificationRequest = (customerNumber, data) => {
  return api({
    path: `/account/${customerNumber}/verification/begin`,
    data
  });
};

/* ████████████████ *\
 * ███ FEES-API ███ *
\* ████████████████ */

export const getTariffs = async (customerNumber, accountNumber, transactional = '') => {
  return api({
    // eslint-disable-next-line max-len
    path: `${
      transactional
        ? `/account/${customerNumber}/tariffs?wallet_number=${accountNumber}&transactional=${transactional}`
        : `/account/${customerNumber}/tariffs?&transactional=${transactional}`
    }`,
    method: 'get'
  });
};

export const setUnderReviewStatus = (customerNumber) => {
  return api({ path: `/account/${customerNumber}/kyc/under_review` });
};

/* █████████████████████████████ *\
 * ███ CURRENCY-EXCHANGE-API ███ *
\* █████████████████████████████ */

export const createExchangeQuoteRequest = (customerNumber, data) =>
  api({
    path: `/account/${customerNumber}/exchange/quote/create`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const calculateExchangeDataRequest = (customerNumber, data) =>
  api({
    path: `/account/${customerNumber}/exchange/calculate`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const createExchangeTransferRequest = (customerNumber, data) =>
  api({
    path: `/account/${customerNumber}/exchange/create`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const confirmExchangeTransferRequest = (customerNumber, data) =>
  api({
    path: `/account/${customerNumber}/exchange/confirm`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

/* ███████████████████████████ *\
 * ███ REPRESENTATIVES-API ███ *
\* ███████████████████████████ */

export const getCandidateInfo = (email) =>
  api({
    path: '/account/info',
    data: { email }
  });

export const addRepresentative = ({ customerNumber, email, permissions, securityCode }) =>
  api({
    path: `/account/representative/${customerNumber}/add`,
    data: {
      representative_email: email,
      permissions
    },
    securityCode
  });

export const removeRepresentative = ({ customerNumber, id, securityCode }) =>
  api({
    path: `/account/representative/${customerNumber}/remove/${id}`,
    securityCode: securityCode
  });

export const updateRepresentative = ({ customerNumber, id, permissions, securityCode }) =>
  api({
    path: `/account/representative/${customerNumber}/update/${id}`,
    data: permissions,
    securityCode: securityCode
  });

/* █████████████████████████████ *\
 * ███ ACTIVITY-LOG-API ███ *
\* █████████████████████████████ */

export const getLoginAttempts = async (filter) =>
  api({
    path: '/login_attempt',
    method: 'get',
    pathPrefix: REACT_APP_API_URL_PATH_PREFIX,
    query: filter
  });

/* ████████████████████████████ *\
 * ███ Messenger Controller ███ *
\* ████████████████████████████ */

export const getMessageList = async (customerNumber, filter) => {
  return await api({
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    path: `/account/${customerNumber}/topic/list`,
    method: 'get',
    query: filter
  });
};

export const markTopicAsReadRequest = async (accountNumber, topicId) => {
  return await api({
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    path: `/account/${accountNumber}/topic/${topicId}/read`,
    method: 'get'
  });
};

export const getTopicMessages = async (customerNumber, topicId, query = {}) => {
  return await api({
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    path: `/account/${customerNumber}/topic/${topicId}/message/list`,
    method: 'get',
    query
  });
};

export const createMessage = async (customerNumber, topicId, data) => {
  return await api({
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    path: `/account/${customerNumber}/topic/${topicId}/message/create`,
    data
  });
};

export const confirmMessageCreation = async (customerNumber, topicId, data) => {
  return await api({
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    path: `/account/${customerNumber}/topic/${topicId}/message/create/confirm`,
    method: 'post',
    data
  });
};

export const createTopic = async (customerNumber, data) => {
  return await api({
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    path: `/account/${customerNumber}/topic/create`,
    method: 'post',
    data
  });
};

export const confirmTopicCreation = async (customerNumber, data) => {
  return await api({
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    path: `/account/${customerNumber}/topic/create/confirm`,
    method: 'post',
    data
  });
};

export const downloadMessageDocument = async (customerNumber, documentId, isPreview) => {
  return await api({
    path: `/account/${customerNumber}/document/${documentId}`,
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    method: 'get',
    responseType: 'blob',
    returnFullResponse: true
  }).then((res) => {
    if (isPreview) {
      return downloadFile(res, isPreview);
    } else {
      downloadFile(res);
    }
  });
};

export const uploadMessageDocument = (customerNumber, file) => {
  return api({
    path: `/account/${customerNumber}/document`,
    pathPrefix: REACT_APP_MESSAGER_API_URL_PATH_PREFIX,
    data: file
  });
};

/* █████████████████*\
 * ███ CARDS-API ███ *
\* █████████████████ */

export const getCardsList = async (customerNumber) => {
  return await api({
    path: `/account/${customerNumber}/card/list`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

export const createCardRequest = async (customerNumber, data) => {
  const res = await api({
    path: `/account/${customerNumber}/card/create`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

  return { ...res };
};

export const confirmCardCreation = async (customerNumber, data) =>
  await api({
    path: `/account/${customerNumber}/card/create/confirm`,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    data
  });

export const getCardNumberRequest = async (customerNumber, card_id, data) =>
  await api({
    path: `/account/${customerNumber}/card/${card_id}/number`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const getCardCVVRequest = async (customerNumber, card_id, data) =>
  await api({
    path: `/account/${customerNumber}/card/${card_id}/cvv`,
    data,
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });

export const getCardAuthorizationsListRequest = async (customerNumber, filters = {}) =>
  await api({
    path: `/account/${customerNumber}/card/authorization/list`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX,
    query: filters
  });

export const blockCardRequest = async (customerNumber, card_id) => {
  return await api({
    path: `/account/${customerNumber}/card/${card_id}/block`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

export const unblockCardRequest = async (customerNumber, card_id) => {
  return await api({
    path: `/account/${customerNumber}/card/${card_id}/unblock`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};

export const replaceCardRequest = async (customerNumber, card_id) => {
  return await api({
    path: `/account/${customerNumber}/card/${card_id}/replace`,
    method: 'get',
    pathPrefix: REACT_APP_PAYMENT_API_URL_PATH_PREFIX
  });
};
